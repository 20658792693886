import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import './App.css';

const DM2_NETWORKS = {
  TESTNET: {
    chainId: '0x10CA7',
    chainName: 'DM2verse testnet',
    nativeCurrency: { name: 'OAS', symbol: 'OAS', decimals: 18 },
    rpcUrls: ['https://rpc.testnet.dm2verse.dmm.com'],
    blockExplorerUrls: ['https://explorer.testnet.dm2verse.dmm.com']
  }
};

const TESTTOKEN_ADDRESSES = {
  TESTNET: '0x10d052b5bb7f8b807cc2c734144b17339a5cf7f7',  // Test Token contract
};

const TESTTOKEN_ABI = [
  'function approve(address spender, uint256 amount) public returns (bool)',
  'function allowance(address owner, address spender) public view returns (uint256)',
  'function balanceOf(address account) public view returns (uint256)'
];

const isCorrectNetwork = (chainId) => {
  return chainId.toLowerCase() === DM2_NETWORKS.TESTNET.chainId.toLowerCase();
};

function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState('');
  const [network, setNetwork] = useState('');
  const [chainId, setChainId] = useState('');
  const [signature, setSignature] = useState('');
  const [allowance, setAllowance] = useState('');
  const [balance, setBalance] = useState('');
  const [isSigningMessage, setIsSigningMessage] = useState(false);
  const [isApprovingToken, setIsApprovingToken] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [requiredAmount, setRequiredAmount] = useState(100); // 必要なTestTokenの量
  const [isSwitchingNetwork, setIsSwitchingNetwork] = useState(false); // ネットワーク切り替えフラグ

  const showAlert = (message) => {
    alert(message);
  };

  const handleChainChanged = (newChainId) => {
    newChainId = newChainId.toLowerCase();
    if (newChainId !== chainId.toLowerCase()) {
      const newNetwork = DM2_NETWORKS.TESTNET.chainName;
      setChainId(newChainId);
      setNetwork(newNetwork);
      showAlert(`ネットワークが変更されました: ${newNetwork} (${newChainId})`);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      setProvider(provider);
      
      const setup = async () => {
        const network = await provider.getNetwork();
        const currentChainId = await provider.send("eth_chainId", []);
        setChainId(currentChainId);
        setNetwork(network.name);
      };
      setup();
      
      window.ethereum.on('chainChanged', handleChainChanged);

      // クリーンアップ
      return () => {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, [chainId]);

  const connectWallet = async () => {
    if (provider) {
      try {
        const signer = await provider.getSigner();
        setSigner(signer);
        const address = await signer.getAddress();
        setAddress(address);
        const currentChainId = await provider.send("eth_chainId", []);
        setChainId(currentChainId);
        handleChainChanged(currentChainId); // `handleChainChanged` を呼び出し
        showAlert('ウォレットの接続に成功しました！');
      } catch (error) {
        console.error('ウォレット接続エラー:', error);
        showAlert('ウォレットの接続に失敗しました。もう一度お試しください。');
      }
    }
  };

  const checkWalletConnection = () => {
    if (!signer) {
      showAlert('先にウォレットを接続してください。');
      return false;
    }
    return true;
  };

  const signMessage = async () => {
    if (!checkWalletConnection()) return;
    setIsSigningMessage(true);
    try {
      const signature = await signer.signMessage("こんにちは、DM2verse！");
      setSignature(signature);
      showAlert('メッセージの署名に成功しました！');
    } catch (error) {
      console.error('メッセージ署名エラー:', error);
      showAlert('メッセージの署名に失敗しました。ユーザーが要求を拒否した可能性があります。');
    } finally {
      setIsSigningMessage(false);
    }
  };

  const addNetwork = async () => {
    if (!checkWalletConnection()) return;
    if (!window.ethereum) {
      showAlert('MetaMaskがインストールされていません！');
      return;
    }
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [DM2_NETWORKS.TESTNET]
      });
      showAlert(`${DM2_NETWORKS.TESTNET.chainName}の追加に成功しました！`);
    } catch (error) {
      console.error('ネットワーク追加エラー:', error);
      showAlert(`${DM2_NETWORKS.TESTNET.chainName}の追加に失敗しました。${error.message}`);
    }
  };

  const switchNetwork = async () => {
    if (!checkWalletConnection()) return;
    if (!window.ethereum) {
      showAlert('MetaMaskがインストールされていません！');
      return;
    }
    if (isSwitchingNetwork) return; // ネットワーク切り替え中は処理を中断
    setIsSwitchingNetwork(true);
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: DM2_NETWORKS.TESTNET.chainId }],
      });
      const newChainId = DM2_NETWORKS.TESTNET.chainId;
      setChainId(newChainId);
      handleChainChanged(newChainId); // `handleChainChanged` を呼び出し
      showAlert(`${DM2_NETWORKS.TESTNET.chainName}への切り替えに成功しました！`);
    } catch (error) {
      if (error.code === 4902) {
        await addNetwork();
      } else {
        console.error('ネットワーク切り替えエラー:', error);
        showAlert(`${DM2_NETWORKS.TESTNET.chainName}への切り替えに失敗しました。${error.message}`);
      }
    } finally {
      setIsSwitchingNetwork(false);
    }
  };

  const addTestTokenToMetaMask = async () => {
    if (!checkWalletConnection()) return;
    if (!isCorrectNetwork(chainId)) {
      showAlert('先にDM2verseテストネットに切り替えてください。');
      return;
    }
    try {
      const tokenAddress = TESTTOKEN_ADDRESSES.TESTNET;
      const tokenSymbol = 'TT';
      const tokenDecimals = 18;
      const tokenImage = '';  // TODO:ロゴURLを設定してください

      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
      showAlert('TestTokenトークンをMetaMaskに追加しました！');
    } catch (error) {
      console.error('TestTokenのMetaMask追加エラー:', error);
      showAlert('TestTokenのMetaMaskへの追加に失敗しました。もう一度お試しください。');
    }
  };

  const approveTestToken = async () => {
    if (!checkWalletConnection()) return;
    if (!isCorrectNetwork(chainId)) {
      showAlert('先にDM2verseテストネットに切り替えてください。');
      return;
    }
    setIsApprovingToken(true);
    try {
      const testtokenAddress = TESTTOKEN_ADDRESSES.TESTNET;
      const testtokenContract = new ethers.Contract(testtokenAddress, TESTTOKEN_ABI, signer);
      const amount = ethers.parseUnits('1000000', 18);
      const tx = await testtokenContract.approve(address, amount);
      await tx.wait();
      showAlert('TestTokenのApproveに成功しました！');
    } catch (error) {
      console.error('TestToken Approveエラー:', error);
      showAlert('TestTokenのApproveに失敗しました。もう一度お試しください。');
    } finally {
      setIsApprovingToken(false);
    }
  };

  const getAllowance = async () => {
    if (!checkWalletConnection()) return;
    if (!isCorrectNetwork(chainId)) {
      showAlert('先にDM2verseテストネットに切り替えてください。');
      return;
    }
    try {
      const testtokenAddress = TESTTOKEN_ADDRESSES.TESTNET;
      const testtokenContract = new ethers.Contract(testtokenAddress, TESTTOKEN_ABI, provider);
      const allowance = await testtokenContract.allowance(address, address);
      setAllowance(ethers.formatUnits(allowance, 18));
      showAlert('TestTokenのAllowance取得に成功しました！');
    } catch (error) {
      console.error('TestToken Allowance取得エラー:', error);
      showAlert('TestTokenのAllowance取得に失敗しました。もう一度お試しください。');
    }
  };

  const getBalance = async () => {
    if (!checkWalletConnection()) return;
    if (!isCorrectNetwork(chainId)) {
      showAlert('先にDM2verseテストネットに切り替えてください。');
      return;
    }
    try {
      const testtokenAddress = TESTTOKEN_ADDRESSES.TESTNET;
      const testtokenContract = new ethers.Contract(testtokenAddress, TESTTOKEN_ABI, provider);
      const balance = await testtokenContract.balanceOf(address);
      setBalance(ethers.formatUnits(balance, 18));
      showAlert('TestTokenの残高取得に成功しました！');
    } catch (error) {
      console.error('TestToken残高取得エラー:', error);
      showAlert('TestTokenの残高取得に失敗しました。もう一度お試しください。');
    }
  };

  const executeSpecificAction = async () => {
    if (!checkWalletConnection()) return;
    if (!isCorrectNetwork(chainId)) {
      showAlert('先にDM2verseテストネットに切り替えてください。');
      return;
    }
    setIsProcessing(true);
    try {
      // ここに特定のアクションの実行コードを追加
      showAlert(`購入へ進む処理が成功しました！ ${requiredAmount} TT`);
    } catch (error) {
      console.error('特定のアクション実行エラー:', error);
      showAlert('特定のアクションの実行に失敗しました。もう一度お試しください。');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container">
      <h1>web3-frontend-example</h1>
      <div className="button-group">
        <button onClick={connectWallet}>ウォレット接続</button>
        <button onClick={signMessage} disabled={isSigningMessage || !signer}>
          {isSigningMessage ? '署名中...' : 'メッセージ署名 (フロント処理のみ)'}
        </button>
        <button onClick={addNetwork} disabled={!signer || isCorrectNetwork(chainId)}>DM2 テストネット追加</button>
        <button onClick={switchNetwork} disabled={!signer || isCorrectNetwork(chainId) || isSwitchingNetwork}>
          {isSwitchingNetwork ? 'ネットワーク切替中...' : 'DM2 テストネットに切替'}
        </button>
        <button onClick={addTestTokenToMetaMask} disabled={!isCorrectNetwork(chainId)}>TestTokenをMetaMaskに追加</button>
        <button onClick={approveTestToken} disabled={!isCorrectNetwork(chainId) || isApprovingToken}>
          {isApprovingToken ? 'Approve処理中...' : 'TestToken Approve'}
        </button>
        <button onClick={getAllowance} disabled={!isCorrectNetwork(chainId)}>TestToken Allowance取得</button>
        <button onClick={getBalance} disabled={!isCorrectNetwork(chainId)}>TestToken 残高取得</button>
        <button onClick={executeSpecificAction} disabled={!isCorrectNetwork(chainId) || isProcessing}>
          {isProcessing ? '処理中...' : `購入へ進む - ${requiredAmount} TT (approve/allowanceのテスト)`}
        </button>
      </div>
      <div className="info">
        <p><strong>アドレス:</strong> {address || '未接続'}</p>
        <p><strong>現在のネットワーク:</strong> {network ? `${network} (${chainId})` : '不明'}</p>
        <p><strong>TestToken 残高:</strong> {balance || '未取得'}</p>
      </div>
      {signature && (
        <div className="signature">
          <strong>署名:</strong>
          {signature}
        </div>
      )}
      {allowance && (
        <div className="info">
          <p><strong>TestToken Allowance:</strong> {allowance}</p>
        </div>
      )}
      {(isSigningMessage || isApprovingToken || isProcessing) && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>{isSigningMessage ? 'メッセージ署名中...' : isApprovingToken ? 'TestToken Approve処理中...' : '処理中...'}</p>
        </div>
      )}
    </div>
  );
}

export default App;
